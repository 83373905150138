*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --color-primary: #a483c6;
  --color-secondary: #9571b8;
  --color-tertiary: #764ca1;
  --color-quaternary: #6c4197;
  --color-quinary: #6b4392f2;

  /* --color-primary: #633690f2;
  --color-secondary: #6c4197;
  --color-tertiary: #764ca1;
  --color-quaternary: #9571b8;
  --color-quinary: #a483c6; */
}

.body {
  min-height: 35vh;
  font-family: sans-serif;
  font-weight: 300;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: radial-gradient(circle at 0% 0.5%, rgb(241, 241, 242) 0.1%, rgb(224, 226, 228) 100.2%);

  /* Add the background image */
  background-image: url('../assets/landing-banner.png'); /* Replace 'path/to/your/image.jpg' with the actual path or URL of your image */
  
  /* Set background image size and position properties to fit the container */
  background-size: cover;
  background-position: center;
  
  border-radius: 10px;
  box-shadow: 10px 5px 5px rgb(176, 175, 175);
}

.content {
  display: flex;
  align-content: center;
  justify-content: center;
}


.text_shadows {
  text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary), 12px 12px 0 var(--color-quinary);
  font-weight: bold;
  font-family: sans-serif;
  text-transform: uppercase;
  font-size: calc(1.8rem + 5vw);
  padding-top: 1.5rem;
  text-align: center;
  margin: 0;
  color: var(--color-primary);
  /* //color: transparent;
  //background-color: white;
  //background-clip: text; */
  animation: shadows 1.2s ease-in infinite, move 1.2s ease-in infinite;
  letter-spacing: 0.4rem;
}

.sub-text {
  text-align: center;
  padding-top: 1.5rem;
  color: rgb(226, 220, 220);
}

@keyframes shadows {
  0% {
    text-shadow: none;
  }
  10% {
    text-shadow: 3px 3px 0 var(--color-secondary);
  }
  20% {
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary);
  }
  30% {
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary);
  }
  40% {
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
      12px 12px 0 var(--color-quinary);
  }
  50% {
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
      12px 12px 0 var(--color-quinary);
  }
  60% {
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
      12px 12px 0 var(--color-quinary);
  }
  70% {
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary);
  }
  80% {
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary);
  }
  90% {
    text-shadow: 3px 3px 0 var(--color-secondary);
  }
  100% {
    text-shadow: none;
  }
}

@keyframes move {
  0% {
    transform: translate(0px, 0px);
  }
  40% {
    transform: translate(-12px, -12px);
  }
  50% {
    transform: translate(-12px, -12px);
  }
  60% {
    transform: translate(-12px, -12px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}


@media (max-width: 768px) {
  .body {
    padding: 1rem;
    min-height: 22vh;
    box-shadow: 4px 4px 4px rgb(176, 175, 175);
  }
  .content {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .sub-text {
    font-size: 14px;
    padding-top: 0rem;
  }
  .text_shadows {
    font-size: calc(1.6rem + 4vw);
  }
}